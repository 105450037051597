<template>
  <div class="container-fluid mt-3">
    <Form @submit="addUser" v-slot="{ errors }">
      <div class="d-flex mb-1 align-items-lg-center justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'المستخدمين' , path: '/users'}" current_page="إضافة مستخدم"/>
        <div class="controls-btns d-flex gap-2 mb-2">
          <button class="btn btn-publish" type="submit" @click="addUser" :disabled="loading">حفظ</button>
        </div>
      </div>

     <UserDetails :errors="errors" @handleUpdate="handleOnChange" :handle-change="handleOnChange" :user="user"/>

    </Form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {Form} from "vee-validate";
import {addNewUser} from "@/services/users";
import {useToast} from "vue-toastification";
import UserDetails from "@/views/users/components/userForm";

export default {
  name      : "Add_User",
  components: {
    UserDetails,
    Breadcrumb,
    Form,
  },
  data()
  {
    return {
      toast : useToast(),
      loading         : false,
      user            : {
        first_name  : null,
        last_name   : null,
        display_name: null,
        username    : null,
        email       : null,
        address     : null,
        mobile      : null,
        password    : null,
        type_id     : null,
        image       : null,
        description : null,
        role        : [],
        facebook    : null,
        twitter     : null
      }
    }
  },
  methods : {
    handleOnChange(key, val)
    {
      this.user = {
        ...this.user,
        [key]: val
      }
    },
    addUser()
    {
      this.loading = true
      addNewUser(this.user)
      .then(()=>{
        this.toast.success("تم إضافة المستخدم بنجاح", {
          position: "top-center",
        });
        this.$router.push('/users');
        this.loading = false
      })
      .catch(()=> {
        this.toast.error("حدث خطأ, الرجاء المحاولة مرة أخرى!", {
          position: "top-center",
        });
        this.loading = false})

    },
  }
}
</script>

